export const items = [{
    id: 1,
    text: 'Ready to start'
}, {
    id: 2,
    text: 'In Progress'
}, {
    id: 3,
    text: 'Evaluating'
}, {
    id: 4,
    text: 'Ready to Test'
}, {
    id: 5,
    text: 'Awaiting deploy'
}, {
    id: 6,
    text: 'Other State'
}, {
    id: 7,
    text: 'Closed'
}]

export const footer = [{
    text: 'Create new user'
}, {
    text: 'Manage users'
}]

export const header = [{
    id: 'any',
    text: 'Any Label'
}, {
    id: 'none',
    text: 'No Label'
}]