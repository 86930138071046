<template>
    <div class="filter" :class="isOpen ? 'dropdown-open' : ''" ref="dropdown">
        <button v-if="!renderAsLink" class="dropdown-menu-toggle" @click="isOpen = !isOpen">
            {{ text }}
            <i class="fa fa-angle-down" aria-hidden="true"></i>
        </button>

        <a href="#" v-if="renderAsLink" class="dropdown-menu-toggle-link" @click="isOpen = !isOpen">
            {{ text }}
            <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>

        <div class="dropdown-menu">
            <div class="dropdown-title">
                {{ title }}
                <a href="#" class="dropdown-menu-close" @click.prevent="isOpen = false">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </a>
            </div>

            <div class="dropdown-input-container" v-if="searchable">
                <input type="text" class="dropdown-input" v-model="filterInputValue" @input="userIsTyping" />
                <i class="fa fa-search dropdown-input-icon" aria-hidden="true"></i>
            </div>

            <div class="dropdown-contentx">
                <ul class="dropdown-header-items" v-if="header.length">
                    <li v-for="(item, index) in header" :key="'header' + index">
                        <a href="#" @click.prevent="handleHeaderClick(item, $event)">{{ item.text }}</a>
                    </li>
                </ul>
                <ul class="dropdown-items">
                    <li v-for="item in items" :key="item.id">
                        <a href="#" @click.prevent="handleItemClick(item, $event)">{{ item.text }}</a>
                    </li>
                    <!-- Bottom message -->
                    <li class="bottom-message" v-show="bottomMessage" v-html="bottomMessage"></li>
                </ul>
            </div>

            <div class="dropdown-footer" v-if="footer.length">
                <ul>
                    <li v-for="(item, index) in footer" :key="'footer' + index">
                        <a href="#" @click.prevent="handleFooterClick(item, $event)">{{ item.text }}</a>
                    </li>
                </ul>
            </div>

            <div class="dropdown-loading" :class="isLoading ? 'show' : ''">
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from './utils/debounce'

export default {
    name: 'FilterDropdown',
    props: {
        text: {
            type: String
        },
        title: {
            type: String,
            default: 'Filter'
        },
        header: {
            type: Array,
            default: () => {
                return []
            }
        },
        items: {
            type: Array,
            default: () => {
                return []
            }
        },
        footer: {
            type: Array,
            default: () => {
                return []
            }
        },
        searchable: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        bottomMessage: {
            type: String,
            default: ''
        },
        renderAsLink: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: false,
            selectedValue: [],
            filterInputValue: ''
        }
    },
    created() {
        this.handleFilterClick()
    },
    mounted() {
        this.handleDropdownScroll()
    },
    methods: {
        /**
         * Check if the user has scrolled the dropdown to bottom. If true, emmit an event to parent indicating this.
         *
         * @return {void}
         */
        handleDropdownScroll() {
            this.$refs.dropdown.querySelector('.dropdown-contentx').addEventListener(
                'scroll',
                debounce(e => {
                    if (this.hasReachedBottom(e.target)) {
                        this.$emit('filter-bottom-was-reached')
                    }
                }, 250)
            )
        },

        /**
         * Check if the target was scrolled until bottom.
         *
         * @param  {object HTMLDocument}  target
         * @return {Boolean}
         */
        hasReachedBottom(target) {
            if (target.clientHeight + target.scrollTop === target.scrollHeight) {
                return true
            }

            return false
        },

        /**
         * Handle the click on the filter element. If the user clicks outside it, the modal is closed.
         *
         * @return {viod}
         */
        handleFilterClick() {
            window.addEventListener('click', e => {
                if (!this.isFilterElement(e.target)) {
                    this.isOpen = false
                }
            })
        },

        /**
         * Check if the click happens on the filter dropdown or not.
         *
         * @param  {Event}  e
         * @return {Boolean}
         */
        isFilterElement(e) {
            if (!e.parentNode || e.parentNode.toString() === '[object HTMLDocument]') {
                return false
            }

            if (e.parentNode === this.$refs.dropdown) {
                return true
            }

            return this.isFilterElement(e.parentNode)
        },

        /**
         * Handle the click on the header items.
         *
         * @param  {Object} item
         * @param  {Event} e
         * @return {void}
         */
        handleHeaderClick(item, e) {
            const value = item.id ? item.id : item.text

            this.selectedValue = value
            this.isOpen = false
            this.$emit('filter-selected', value)

            if (this.multiple) {
                // Clearing all selected items
                let oldSelection = this.$refs.dropdown.querySelectorAll('.filter li a.is-selected')
                oldSelection.forEach(item => {
                    item.classList.remove('is-selected')
                })

                e.target.classList.add('is-selected')

                return
            }

            let oldSelection = this.$refs.dropdown.querySelector('.filter li a.is-selected')
            if (oldSelection) {
                oldSelection.classList.remove('is-selected')
            }

            e.target.classList.add('is-selected')
        },

        /**
         * Handle the click on the body items.
         *
         * @param  {Object} item
         * @param  {Event} e
         * @return {void}
         */
        handleItemClick(item, e) {
            const value = item.id ? item.id : item.text

            if (this.multiple) {
                if (e.target.classList.contains('is-selected')) {
                    e.target.classList.remove('is-selected')
                    let index = this.selectedValue.indexOf(value)
                    this.selectedValue.splice(index, 1)
                    this.$emit('filter-selected', JSON.parse(JSON.stringify(this.selectedValue)))
                } else {
                    e.target.classList.add('is-selected')

                    // If one of the header items was selected, we need to clear it too and change the selected value to
                    // an array to avoid errors
                    if (typeof this.selectedValue !== 'object') {
                        this.$refs.dropdown.querySelector('.filter li a.is-selected').classList.remove('is-selected')
                        this.selectedValue = []
                    }

                    this.selectedValue.push(value)
                    this.$emit('filter-selected', JSON.parse(JSON.stringify(this.selectedValue)))
                }

                return
            }

            this.selectedValue = value
            this.isOpen = false
            this.$emit('filter-selected', value)

            let oldSelection = this.$refs.dropdown.querySelector('.filter li a.is-selected')
            if (oldSelection) {
                oldSelection.classList.remove('is-selected')
            }

            e.target.classList.add('is-selected')
        },

        /**
         * Handle the click on an item in the footer section.
         *
         * @param  {Object} item
         * @param  {Event} e
         * @return {void}
         */
        handleFooterClick(item, e) {
            const value = item.id ? item.id : item.text
            this.$emit('footer-was-clicked', value)
        },

        /**
         * Emmit an event to parent when the user is typing on the search field
         *
         * @param  {Event} e
         * @return {void}
         */
        userIsTyping(e) {
            this.$emit('filter-input', e.target.value)
        }
    },
    watch: {
        /**
         * Watch for change on the `isOpen` property, to send an event to parent when the filter is opened/closed.
         *
         * @param  {Boolean}  val
         * @param  {Boolean}  oldVal
         * @return {Boolean}
         */
        isOpen(val, oldVal) {
            if (!val) {
                this.$emit('filter-closed', this.selectedValue)
                return
            }

            this.$emit('filter-opened')
        }
    }
}
</script>
<style lang="sass">
$filter-button-background-color: #fff
$filter-button-color: #626262
$filter-button-border-size: 1px
$filter-button-border-style: solid
$filter-button-border-color: #c9c9c9
$filter-button-border-radius: 3px
$filter-button-font-size: 15px
$filter-button-min-width: 150px
$filter-button-padding: 6px 10px

$filter-dropdown-menu-background: #fff
$filter-dropdown-menu-width: auto
$filter-dropdown-menu-padding: 2px 8px
$filter-dropdown-menu-border: 1px solid #fff
$filter-dropdown-menu-border-radius: 4px
$filter-dropdown-menu-color: #555

$filter-dropdown-title-color: #5c5c5c
$filter-dropdown-title-border: 1px solid rgba(0,0,0,0.1)
$filter-dropdown-title-close-color: #ababab

$filter-input-icon-color: #ababab
$filter-input-color: #555
$filter-input-border: 1px solid rgba(0,0,0,0.1)
$filter-input-border-radius: 2px

$filter-dropdown-divider-color: 1px solid rgba(0,0,0,0.1)
$filter-dropdown-item-hover-color: #fbfbfb
$filter-dropdown-loading-background: rgba(255, 255, 255, 0.7)

$filter-dropdown-bottom-message-color: #adadad
$filter-dropdown-bottom-message-font-weight: bold
$filter-dropdown-bottom-message-font-size: 0.8em
$filter-dropdown-bottom-message-padding: 5px 0
$filter-dropdown-bottom-message-background: #fff
$filter-dropdown-bottom-message-align: center

.dropdown-menu-toggle
  background: $filter-button-background-color
  color: $filter-button-color
  border: $filter-button-border-size $filter-button-border-style $filter-button-border-color
  border-radius: $filter-button-border-radius
  font-size: $filter-button-font-size
  min-width: $filter-button-min-width
  padding: $filter-button-padding
  text-align: left
  position: relative
  cursor: pointer
  display: flex
  justify-content: space-between

.dropdown-menu-toggle-link
  text-decoration: none
  color: $filter-button-color

.dropdown-menu
  background: $filter-dropdown-menu-background
  width: $filter-dropdown-menu-width
  padding: $filter-dropdown-menu-padding
  border-radius: $filter-dropdown-menu-border-radius
  border: $filter-dropdown-menu-border
  color: $filter-dropdown-menu-color
  margin-top: 5px
  position: absolute
  display: none
  z-index: 10

.dropdown-open .dropdown-menu
  display: block

.dropdown-title
  color: $filter-dropdown-title-color
  border-bottom: $filter-dropdown-title-border
  text-align: center
  white-space: nowrap
  font-weight: 600
  padding: 8px 0
  margin-right: 3px

.dropdown-menu-close
  color: $filter-dropdown-title-close-color
  position: absolute
  right: 23px

.dropdown-input-container
  margin: 10px 15px 10px 0
  position: relative

  .fa
    font-size: .9em
    position: absolute
    top: 9px
    right: 0
    color: $filter-input-icon-color

.dropdown-input
  color: $filter-input-color
  border: $filter-input-border
  border-radius: $filter-input-border-radius
  width: 100%
  padding: 0 5px
  line-height: 30px

.dropdown-contentx, .dropdown-footer
  max-height: 210px
  overflow-y: scroll
  margin: 10px 0

  ul:first-child
    padding-top: 0

  ul:last-child
    padding-bottom: 0

  ul
    list-style: none
    padding: 10px 0
    margin: 0

    li.divider
      border-bottom: $filter-dropdown-divider-color
      margin: 0 0 5px 0

    li a
      padding: 5px 30px
      display: block
      text-decoration: none
      color: $filter-dropdown-menu-color
      position: relative

    li a:hover
      background: $filter-dropdown-item-hover-color

    li a.is-selected::before
      content: "\f00c"
      font: normal normal normal 14px/1 FontAwesome
      color: $filter-dropdown-menu-color
      margin-right: 5px
      position: absolute
      top: 8px
      left: 10px

  ul.dropdown-header-items
    border-bottom: $filter-dropdown-divider-color
    margin-right: 5px

.dropdown-footer
  overflow-y: auto
  border-top: $filter-dropdown-divider-color
  margin-top: 10px
  font-size: 0.9em

.dropdown-loading
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  justify-content: center
  align-items: center
  background: $filter-dropdown-loading-background
  display: none

.dropdown-loading.show
  display: flex

.dropdown-items
  position: relative

.bottom-message
  text-align: $filter-dropdown-bottom-message-align
  font-weight: $filter-dropdown-bottom-message-font-weight
  color: $filter-dropdown-bottom-message-color
  font-size: $filter-dropdown-bottom-message-font-size
  padding: $filter-dropdown-bottom-message-padding
  background: $filter-dropdown-bottom-message-background
  position: absolute
  bottom: 0
  width: 100%
</style>
