<template>
    <!-- form modal -->

    <div
        v-if="showTransferModal"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showTransferModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="modalRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Account Transfer</div>
                <div>
                    <router-link to="#">
                        <img :src="require('@/assets/images/Close-icon.svg')" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>
            <form class="entity-form" @submit.prevent="onSubmit">
                <div class="pa3">
                    <div class="flex flex-wrap justify-between">
                        <!-- Product name -->
                        <div class="pt2 w-100 w-50-l pr2-l">
                            <div class="pv2 font-w1">
                                <label for="name">From</label>
                            </div>
                            <select v-model="state.fromAccount" class="w-100" required>
                                <option value="">Select account</option>
                                <option v-for="(account, index) of state.data" :value="account?.type" :key="index">
                                    {{ account?.type }} ({{ formatAmount(account?.balance, $store.state.Settings?.currency) }})
                                </option>
                            </select>
                        </div>

                        <div class="pt2 w-100 w-50-l pl2-l">
                            <div class="pv2 font-w1">
                                <label for="name">To</label>
                            </div>
                            <select v-model="state.toAccount" class="w-100" required>
                                <option value="">Select account</option>
                                <option v-for="(account, index) of state.data" :value="account?.type" :key="index">
                                    {{ account?.type }} ({{ formatAmount(account?.balance, $store.state.Settings?.currency) }})
                                </option>
                            </select>
                        </div>

                        <div class="pt2 w-100 w-100-l pl2-l">
                            <div class="pv2 font-w1"><label for="name">Amount</label></div>
                            <CurrencyInput
                                class="product-input w-100"
                                v-model="state.amount"
                                :options="numberFormat"
                                placeholder="0"
                                :disabled="
                                    state.fromAccount === '' || state.toAccount === '' || state.fromAccount === state.toAccount
                                "
                                required
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="flex flex-wrap justify-end" style="gap: 1rem">
                        <button class="mt1" @click.prevent="closeModal">Cancel</button>
                        <button class="mt1" type="submit" :disabled="state.disableSubmitButton || state.amount <= 0">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { formatAmount } from '@/utils/lib'
import CurrencyInput from '@/components/CurrencyInput'

export default {
    name: 'TransferModal',
    components: { CurrencyInput },
    props: {
        closeModal: Function
    },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true
            }
        }
    },

    setup(props, { emit }) {
        const modalRef = ref(null)
        const store = useStore()
        const showTransferModal = computed(() => store.state?.Transaction?.showTransferModal)
        const accountDetails = computed(() => store?.state?.Transaction?.accountDetails)

        const initialState = {
            data: [],
            fromAccount: '',
            toAccount: '',
            amount: 0,
            disableSubmitButton: false
        }
        const state = reactive(initialState)

        const closeModal = () => {
            props.closeModal()
            state.data = []
            state.fromAccount = ''
            state.toAccount = ''
            state.amount = 0
        }

        const onSubmit = () => {
            state.disableSubmitButton = true
            const payload = {
                fromAccount: state.fromAccount,
                toAccount: state.toAccount,
                amount: state.amount
            }

            store
                .dispatch('Transaction/accountTransfer', payload)
                .then(_ => {
                    state.disableSubmitButton = false
                    emit('on-complete')
                    store.dispatch('Transaction/getAccountDetails')
                    closeModal()
                })
                .catch(error => {
                    state.disableSubmitButton = false
                    console.error(error)
                })
        }

        const handleOnClickOutsideModal = e => {
            if (modalRef?.value && !modalRef?.value.contains(e.target)) {
                closeModal()
            }
        }
        watch(accountDetails, newValue => {
            if (newValue) {
                state.data = Object.values(newValue)?.filter(value => value.type !== null && parseInt(value.balance) !== 0)
            }
        })

        onMounted(() => {
            store.dispatch('Transaction/getAccountDetails')
        })

        return {
            state,
            onSubmit,
            showTransferModal,
            modalRef,
            handleOnClickOutsideModal,
            formatAmount
        }
    }
}
</script>

<style>
.entity-form input[type='radio'] {
    background: unset;
    border: none;
    box-shadow: none;
    padding: unset;
    width: unset;
}
</style>
