<template>
    <div
        tabindex="0"
        @keydown.esc="toggleAccountBox()"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showBalanceBox && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-30-l w-90 pv2 center box-border" style="background: white" ref="accountBoxRef">
            <div class="flex justify-between pa3">
                <div></div>
            </div>
            <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                <!-- center -->

                <div class="box-border-bottom f3 pa3 b">Account Balance</div>

                <div v-for="item of accountDetails" v-bind:key="item" class="flex justify-between pa3">
                    <div class="flex flex-column">
                        <div class="f4">
                            <p>{{ item?._id }}</p>
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="f3">
                            <span class="f4">{{ formatAmount(item?.totalAmount.toString(), $store.state.Settings?.currency) }}</span>
                        </div>
                    </div>
                </div>

                <div class="flex justify-between box-border ma3 pa3">
                    <div class="flex flex-column">
                        <div class="f4">
                            <p><strong>Total</strong></p>
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="f3">
                            <span class="f4">{{ formatAmount(accountBalance, $store.state.Settings?.currency) }}</span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, ref, onMounted, watch, reactive } from 'vue'
import { formatAmount } from '@/utils/lib'

export default {
    name: 'BalanceDetails',
    props: {
        balance: {
            type: Object,
            required: true
        }
    },

    setup() {
        const accountBoxRef = ref(null)
        const store = useStore()
        const showBalanceBox = computed(() => store?.state?.Transaction?.showBalanceBox)
        const accountDetails = computed(() => store?.state?.Transaction?.accountDetails)
        const accountBalance = computed(() => store?.state?.Transaction?.accountBalance)

        const state = reactive({
            cash: {},
            credit: {},
            debit: {},
            bank: {},
            data: [],
            total: 0
        })

        onMounted(() => {
            store.dispatch('Transaction/getAccountDetails')
        })
        watch(accountDetails, newValue => {
            if (newValue) {
                state.data = Object.values(newValue)?.filter(value => value.type !== null)
                // state.total = state.data?.reduce((total, item) => {
                //     if(isNaN(item.balance)) return total
                //     return total + parseInt(item.balance)
                // }, 0)
            }
        })
        const toggleAccountBox = (payload = false) => {
            store.dispatch('Transaction/showBalanceBox', false)
        }

        const handleOnClickOutsideModal = e => {
            if (accountBoxRef.value && !accountBoxRef.value.contains(e.target)) {
                toggleAccountBox()
            }
        }

        return {
            toggleAccountBox,
            handleOnClickOutsideModal,
            showBalanceBox,
            accountBoxRef,
            state,
            accountBalance,
            accountDetails,
            formatAmount
        }
    }
}
</script>

<style scoped></style>
