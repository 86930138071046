<template>
    <app-wrapper>
        <template v-slot:child>
            <BalanceBox />
            <TransferModal :closeModal="() => toggleTransferModal(false)" @on-complete="getAllTransactions" />
            <TransactionImportModal :closeModal="() => toggleTransactionImportModal(false)" />
            <!--Transaction , import button, send statement & new -->
            <div class="flex flex-wrap justify-between items-center w-90 center pv4-l pv3 mini-spacing">
                <div>
                    <p class="f3 b">
                        {{ trxValue }}
                        <strong
                            ><font-awesome-icon
                                icon="fa-solid fa-question-circle"
                                size="lg"
                                class="text-muted clickModalIcon"
                                @click="clickModalTxn = true"
                                style="cursor: pointer"
                        /></strong>
                    </p>

                    <div class="dashboard-inquiry-modal" v-if="clickModalTxn">
                        This shows an overview of all your transactions including Invoices. Click
                        <a
                            href="https://help.simplebks.com/en-gb/c/63eb803e847ed495aec7c4fe-record-keeping"
                            target="_blank"
                            style="color: blue"
                            >here</a
                        >
                        to learn more
                    </div>
                </div>
                <div class="scrollmenu flex">
                    <router-link
                        active-class="active"
                        :to="{ name: 'CreateSalesTransaction' }"
                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(22)"
                    >
                        <button class="mr2 flex items-center" style="height: 45px">Add New Record</button>
                    </router-link>
                    <button
                        style="background-color: white; border: 1px solid #132C8C; color: #132C8C;"
                        class="dropdown-togglex "
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        More <font-awesome-icon icon="fa-solid fa-caret-down" class="fa-2x" />
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style=" z-index: 2000">
                        <li v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(52)" style="cursor: pointer" @click="toggleTransactionImportModal(true)">Import</li>
                        <li v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(53)" style="cursor: pointer" @click="exportTransactions">Export</li>
                    </ul>
                    <!-- <button @click="toggleTransferModal(true)" class="btn2 ml2 buttonhoverall">Transfer</button> -->
                </div>
            </div>
            <!--Transaction , inport button, send statement & new -->

            <!-- <div class="flex flex-wrap w-90 center items-center justify-end">
                <div class="flex justify-start items-center w-60-l w-100 mt0-l mt2">
                    <select
                        @change="ev => onChangeFixed(ev.target.value)"
                        v-model="state2.period"
                        class="card-boxx mb3 mb0-l"
                        id="period"
                        style="padding: 13px 0"
                    >
                        <option value="thisMonth">This month</option>
                        <option value="lastMonth">Last month</option>
                        <option value="thisYear">This year</option>
                        <option value="thisWeek">Last 7 days</option>
                    </select>
                    <input
                        @change="onHandleCustomRange"
                        v-model="state2.start"
                        type="date"
                        name="start"
                        class="input-style card-box mb3 mb0-l"
                        :max="todaysDate"
                         style="padding: 13px 0"
                    />
                    <input
                        @change="onHandleCustomRange"
                        v-model="state2.end"
                        type="date"
                        name="end"
                        class="input-style card-box mb3 mb0-l"
                        :max="todaysDate"
                         style="padding: 13px 0"
                    />

                    <div class="filter card-boxx mb3 mb0-l mr4">
                        <div class="tc flex justify-center items-center">
                            <div @click="refreshHandler"  style="padding: 8px 0">
                                <img :src="require('@/assets/images/Reload.svg')" class="pl2 pr2" alt=" " />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-40-l w-100">

                </div>
            </div> -->

            <!-- income/sales, COGS/purchase, expenses , balance -->
            <div class="w-90 center flex flex-wrap pt3">
                <!-- income/sale -->
                <div class="pv4 pl3-l pl2 w-25-l w-50 adj-text" style="border: 1px solid #e3e8ee">
                    <div>
                        <p class="pl2 font-w1">Sales</p>
                    </div>

                    <div style="color: #132c8c">
                        <h3 class="mb0">{{ formatAmount(summary?.incomeSale, $store.state.Settings?.currency) }}</h3>
                    </div>
                </div>
                <!-- income/sale -->

                <!-- Cogs/purchase -->
                <div class="pv4 pl3-l pl2 w-25-l w-50 adj-text" style="border: 1px solid #e3e8ee">
                    <div>
                        <p class="pl2 font-w1">Purchases</p>
                    </div>

                    <div style="color: #132c8c">
                        <h3 class="mb0">{{ formatAmount(summary?.purchases, $store.state.Settings?.currency) }}</h3>
                    </div>
                </div>
                <!-- cogs/purchase-->

                <!-- Expenses -->
                <div class="pv4 pl3-l pl2 w-25-l w-50 adj-text" style="border: 1px solid #e3e8ee">
                    <div>
                        <p class="pl2 font-w1">Expenses</p>
                    </div>

                    <div style="color: #132c8c">
                        <h3 class="mb0">{{ formatAmount(summary?.expenses, $store.state.Settings?.currency) }}</h3>
                    </div>
                </div>
                <!-- Expenses-->

                <!-- Balance -->
                <a
                    href="#"
                    @click="showBalanceDetails"
                    class="pv4 pl3-l pl2 w-25-l w-50 adj-text"
                    style="border: 1px solid #e3e8ee"
                >
                    <div>
                        <a class="pl2 font-w1">Account Balances</a>
                    </div>

                    <div style="color: #132c8c">
                        <h3 class="mb0" v-if="role === 'owner' || role === 'owner'">
                            {{ formatAmount(summary?.balance, $store.state.Settings?.currency) }}
                        </h3>
                        <h3 class="mb0" v-else>N/A</h3>
                    </div>
                </a>
                <!--  -->
            </div>

            <!-- GRAPH HERE -->

            <!-- All, sales, purchase, expenses, date , filter -->
            <div class="w-90 center pt4" v-if="role" id="tx-header">
                <div class="filter-wrapper box-border-top box-border-bottom mb1 pv2">
                    <div class="flex flex-wrap center items-center justify-between pb3">
                        <div class="flex flex-column items-start w-100 w-50-l pr2">
                            <label class="b mv1">Search</label>
                            <SearchInput
                                v-if="role"
                                placeholder="Search transactions..."
                                v-model="state.searchVal"
                                @change="updateRoute('q', state.searchVal)"
                            />
                        </div>
                        <div class="flex flex-column items-start w-50 w-25-l ph2 pr1">
                            <label class="b mv1">Date Range</label>
                            <select
                                @change="ev => onChangeFixed(ev.target.value)"
                                v-model="state2.period"
                                class="w-100 filter-input"
                                id="period"
                            >
                                <option value="thisMonth">This month</option>
                                <option value="lastMonth">Last month</option>
                                <option value="thisYear">This year</option>
                                <option value="thisWeek">Last 7 days</option>
                            </select>
                        </div>
                        <div class="flex flex-column items-start w-50 w-25-l ph2 pl1">
                            <label class="b mv1">Date</label>
                            <VueDatepickerUi
                                range
                                circle
                                show-clear-button
                                lang="en"
                                v-model="state.dateRange"
                                position="right"
                                placeholder="Select date range"
                            />
                        </div>
                    </div>

                    <div class="flex flex-wrap center items-center justify-between pb3">
                        <div class="flex flex-column items-start w-50 w-25-l ph2 pr1">
                            <label class="b mv1">Type</label>
                            <select
                                v-model="state.type"
                                class="w-100 filter-input"
                                @change="updateRoute('type', state.type)"
                            >
                                <option value="all">All </option>
                                <option v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(2)" value="sales">Sales</option>
                                <option v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(3)" value="purchases">Purchases</option>
                                <option v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(3)" value="produce">Production</option>
                                <option v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(4)" value="expenses">Expenses</option>
                                <option v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(76)" value="transfer">Transfer</option>
                                <option v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(77)" value="purchase_order">Purchase Order</option>
                                <option v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(78)" value="asset_buy">Asset Buy</option>
                                <option v-if="role === 'admin' || role === 'owner' || rolePermissions.includes(42)" value="others">Others</option>
                            </select>
                        </div>
                        <div class="flex flex-column items-start w-50 w-25-l ph2 pl1">
                            <label class="b mv1">Subtype</label>
                            <select
                                v-model="state.subtype"
                                class="w-100 filter-input"
                                @change="updateRoute('subtype', state.subtype)"
                            >
                                <option value="">All</option>
                                <optgroup label="Money in">
                                    <option v-for="(data, index) in inflows" :key="'mi' + index" :value="data.value">
                                        {{ data.name }}
                                    </option>
                                </optgroup>
                                <optgroup label="Expenses">
                                    <option v-for="(type, index) in expenseTypes" :key="'exp' + index" :value="type.value">
                                        {{ type.name }}
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                        <div class="flex flex-column items-start w-50 w-25-l ph2 pr1">
                            <label class="b mv1">Channel</label>
                            <select
                                v-model="state.channel"
                                class="w-100 filter-input"
                                @change="updateRoute('channel', state.channel)"
                            >
                                <option value="">All</option>
                                <option value="direct">Direct</option>
                                <option value="invoice">Invoice</option>
                                <option value="POS">POS</option>
                                <option value="paystack">Paystack</option>
                            </select>
                        </div>
                        <div class="flex flex-column items-start w-50 w-25-l ph2 pl1">
                            <label class="b mv1">Staff</label>
                            <select
                                v-model="state.staff"
                                class="w-100 filter-input"
                                @change="updateRoute('staff', state.staff)"
                                :disabled="role !== 'admin' && role !== 'owner' && !rolePermissions.includes(100)"
                            >
                                <option value="">All</option>
                                <option v-for="(staff, index) in staffList" :key="'staff' + index">
                                    {{ staff.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="flex flex-wrap center items-end justify-between pb3">
                        <div class="flex flex-column items-start w-50 w-25-l ph2 pr1">
                            <label class="b mv1">Payment Method</label>
                            <select
                                v-model="state.paymentMethod"
                                class="w-100 filter-input"
                                @change="updateRoute('paymentMethod', state.paymentMethod)"
                            >
                                <option value="">All</option>
                                <option value="Cash">Cash</option>
                                <option v-for="(bank, index) in bankAccounts" :key="'pm' + index">
                                    {{ bank?.aliasName || bank?.bankName }}
                                </option>
                            </select>
                        </div>
                        <div class="flex flex-column items-start w-50 w-50-l ph2 pl1">
                            <label class="b mv1">Tag</label>
                            <vue3-tags-input
                                v-model:tags="state.tags"
                                v-model="state.tag"
                                :select="true"
                                :select-items="state.myTags"
                                @on-select="handleSelectedTag"
                                @on-tags-changed="handleChangeTag"
                                placeholder="Select tag"
                            >
                            <template #item="{ tag }">
                                {{ tag.text }}
                            </template>
                            <template #no-data>
                                No results for tag
                            </template>
                            <template #select-item="tag">
                                {{ tag.text }}
                            </template>
                            </vue3-tags-input>
                        </div>
                        <div class="flex flex-column items-end">
                            <button class="btn search-btn" @click="search">Search</button>
                        </div>
                    </div>
                </div>

                <div v-if="transactionErrors?.length" class="alert alert-danger">
                    <div class="b">The following rows has error and will be skipped:</div>
                    <table>
                        <tr v-for="(row, index) of transactionErrors" :key="index">
                            <td class="bb b--black-20 tl pr3">{{ `Row ${index + 1}` }}</td>
                            <td class="bb b--black-20 tl pr3">{{ row.errMessage }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <!-- All, Sales, Expenses, Purchase & Other -->

            <TableList
                :transactions="transactions"
                :startDate="state2.start"
                :endDate="state2.end"
                :on-navigate="onNavigateAllTransactions"
                action="getAllTransactions"
                :role="role"
            />
        </template>
    </app-wrapper>
</template>

<script>
import { computed, onMounted, reactive, watch, ref } from 'vue'
import moment from 'moment-timezone'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import AppWrapper from '@/layout/AppWrapper'
import { formatDate, formatAmount, formatDateFilter, getLastSevenDaysDateRange } from '@/utils/lib'
import { items, header, footer } from '@/utils/filterConfig'
import SearchInput from 'vue-search-input'
import VueTagsInput from "@sipec/vue3-tags-input"
import Vue3TagsInput from 'vue3-tags-input'
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import FilterDropdown from '@/components/FilterDropdown'
import TableList from '@/views/Transactions/widgets/TableList'
import BalanceBox from '@/views/Transactions/widgets/BalanceDetails'
import TransferModal from '@/views/Transactions/widgets/TransferModal'
import TransactionImportModal from '@/views/Transactions/widgets/TransactionImportModal'
import { Bar, Line } from 'vue-chartjs'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Plugin,
    Filler
} from 'chart.js'

ChartJS.register(Filler, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement)

export default {
    name: 'Transactions',
    components: {
        AppWrapper,
        TableList,
        BalanceBox,
        TransferModal,
        TransactionImportModal,
        Bar,
        Line,
        SearchInput,
        FilterDropdown,
        VueTagsInput,
        Vue3TagsInput,
        VueDatepickerUi,
    },

    setup() {
        const store = useStore()
        const orgData = computed(() => store?.state?.Auth?.userData)
        const summary = computed(() => store?.state?.Transaction.summary)
        const transactions = computed(() => store?.state?.Transaction.transactions)
        const sales = computed(() => store?.state?.Transaction.sales)
        const purchases = computed(() => store?.state?.Transaction.purchases)
        const expenses = computed(() => store?.state?.Transaction.expenses)
        const others = computed(() => store?.state?.Transaction.others)
        const role = computed(() => store?.state?.Auth?.role)
        const todaysDate = computed(() => store?.state?.General?.todaysDate)
        const expensesOverview = computed(() => store?.state?.Transaction.expensesGraphData)
        const salesOverview = computed(() => store?.state?.Transaction.salesGraphData)
        const purchaseOverview = computed(() => store?.state?.Transaction.purcahasesGraphData)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const currency = computed(() => store?.state?.Settings?.currency)
        const plan = computed(() => store.state?.Settings?.paymentPlan)
        const transactionErrors = computed(() => store?.state?.Transaction.transactionErrors)
        const trxValue = ref(null);

        const route = useRoute()
        const router = useRouter()

        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()
        const day = today.getDate()
        const staffFilter = ref([])
        const staffList = computed(() => store.state.Settings.members)
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)

        const inflows = [
            { value: 'capital', name: 'Capital' },
            { value: 'borrowings', name: 'Borrowings' },
            // { value: 'advance_payment', name: 'Customer Advance/Deposit' },
            { value: 'service', name: 'Service Charge' },
            { value: 'shipping', name: 'Shipping Fee' },
            { value: 'vat_received', name: 'VAT Received' },
            { value: 'interest', name: 'Interest Earning' },
            // { value: 'purchase_return', name: 'Purchase Return' },
            // { value: 'stock_introduced', name: 'Stock Introduced' },
            // { value: 'discount', name: 'Discount Received' },
            { value: 'debt_repayment', name: 'Debt Repayment' },
            { value: 'gift_received', name: 'Gift Received' },
            // { value: 'assets_sales', name: 'Asset Sales' },
            { value: 'others_in_amount', name: 'Other Income' }
            // { value: 'damaged_missing_used_stock', name: 'Damaged/Missing/Stock used in promotion'}
        ]

        const expenseTypes = [
            { value: 'transport', name: 'Transportation' },
            { value: 'commission', name: 'Commission' },
            // { value: 'input_raw_materials', name: 'Raw Materials' },
            // { value: 'ads', name: 'Advertisement' },
            { value: 'packaging', name: 'Packaging & Branding' },
            // { value: 'discount', name: 'Discount Given' },
            // { value: 'fees', name: 'Fees' },
            { value: 'rent', name: 'Rent' },
            { value: 'utility', name: 'Utility' },
            { value: 'interest_expense', name: 'Interest Expense' },
            { value: 'others', name: 'Other Expenses' },
            { value: 'home_office_cost', name: 'Home/Office Fixed Cost' },
            { value: 'furniture_equipment_machinery', name: 'Furniture/Equipment/Machin...' },
            { value: 'office_supplies', name: 'Office Supplies' },
            { value: 'advertising_marketing', name: 'Advertising & Marketing' },
            { value: 'website_software', name: 'Website/Software Sub...' },
            { value: 'entertainment', name: 'Entertainment' },
            { value: 'business_meals_travel_expense', name: 'Business Meals & Travel ...' },
            { value: 'salary', name: 'Salary/Payroll' },
            { value: 'vehicle_expenses', name: 'Vehicle Expenses' },
            { value: 'taxes', name: 'Taxes' },
            { value: 'business_insurance', name: 'Business Insurance' },
            { value: 'license_registration', name: 'License & Registration' },
            { value: 'professional_business_fees', name: 'Professional & Business Fees' },
            { value: 'trainings', name: 'Trainings' },
            { value: 'input_raw_materials', name: 'General Accessories/Raw ...' },
            { value: 'bank', name: 'Bank Fees/Charges' },
            { value: 'electricity', name: 'Electricity' },
            { value: 'telephone', name: 'Telephone' },
            { value: 'csr', name: 'CSR' },
            { value: 'maintenance_repairs', name: 'Maintenance/Repairs' },
            { value: 'cost_of_goods_sold', name: 'Cost of Service Rendered' },
            { value: 'employee_benefits', name: 'Employee benefits' }
        ]

        const searchVal = route.query?.q ? route.query.q : ''
        const type = route.query?.type ? route.query.type : 'all'
        const channel = route.query?.channel ? route.query.channel : ''
        const staff = route.query?.staff ? route.query.staff :  ''
        const paymentMethod = route.query?.paymentMethod ? route.query.paymentMethod : ''
        const subtype = route.query?.subtype ? route.query.subtype : ''

        const state2 = reactive({
            filterType: '',
            period: 'thisMonth',
            start: formatDateFilter(new Date(year, month, 1)),
            end: formatDateFilter(new Date()),
            howLongInNumber: 30,
            oneOfDaysWeeksMonthsYears: 'days',
            mode: 'desc'
        })

        const state = reactive({
            isTransactions: true,
            isSales: false,
            isPurchases: false,
            isExpenses: false,
            isOthers: false,
            salesGraphFilter: 'last30Days',
            purchaseGraphFilter: 'last30Days',
            expenseGraphFilter: 'last30Days',
            salesGraphData: {},
            purchasesGraphData: {},
            expensesGraphData: {},
            canViewAllTransaction: true,
            canViewSalesTransaction: true,
            canViewPurchasesTransaction: true,
            canViewExpensesTransaction: true,
            searchVal: searchVal,
            type: type,
            dateRange: [new Date(year, month, 1), new Date()],
            channel: channel,
            staff: staff,
            paymentMethod: paymentMethod,
            subtype: subtype,
            tags: [],
            tag: '',
            myTags: [],
        })

        const setStaffFilter = val => {
            staffFilter.value = [...val]
        }

        const onChangeTable = tableName => {
            const tables = Object.keys(state)
            tables.forEach(stateKey => {
                if (stateKey !== 'searchVal') {
                    if (tableName === stateKey) {
                        state[tableName] = true
                    } else {
                        state[stateKey] = false
                    }
                }
            })
        }

        const updateRoute = (field, value) => {
            const query = route.query
            if (field === 'date') {
                const from = state2.start
                const to = state2.end
                value = `${from},${to}`
            }
            router.replace({
                name: 'Transactions',
                query: { ...query, [field]: value }
            });
        }

        watch(
            () => role.value,
            newValue => {
                if (newValue === 'salesTeller') {
                    onChangeTable('isSales')
                }
            }
        )

        watch(
            () => orgData.value,
            newValue => {
                if (role.value !== 'admin' && role.value !== 'owner' && !rolePermissions.value.includes(100)) {
                    state.staff = orgData.value.fullName
                }
            }
        )

        const getAllTransactions = () => {
            state2.start = state.dateRange[0]
            state2.end = state.dateRange[1]
            const query = {
                page: route.query.page || 1,
                limit: route.query.limit || 25,
                filterType: state2.filterType || 'fixed',
                start: formatDate(state2.start) + ' 00:00:00',
                end: formatDate(state2.end) + ' 23:59:59',
                howLongInNumber: state2.howLongInNumber || 30,
                oneOfDaysWeeksMonthsYears: state2.oneOfDaysWeeksMonthsYears || 'days',
                sort: 'date',
                filterByType: route.query.type || state.type,
                filterByChannel: route.query.channel || state.channel,
                filterByStaff: route.query.staff || state.staff,
                filterByPaymentMethod: route.query.paymentMethod || state.paymentMethod,
                filterBySubtype: route.query.subtype || state.subtype
            }

            if (role.value === 'admin' || role.value === 'owner') {
                store.dispatch('Transaction/getAllTransactions', query)
                store.dispatch('Transaction/getAllSalesTransactions', query)
                store.dispatch('Transaction/getAllPurchaseTransactions', query)
                store.dispatch('Transaction/getAllOtherTransactions', query)
                store.dispatch('Transaction/getTransactionSummary', query)
                store.dispatch('Transaction/getAllExpenseTransactions', query)
            } else {
                store.dispatch('Transaction/getAllSalesTransactions', query)
            }
        }

        const onChangeFixed = async val => {
            if (val === 'thisMonth') {
                state2.start = moment(new Date(year, month, 1)).format('YYYY-MM-DD')
                state2.end = moment(new Date()).format('YYYY-MM-DD')
                state.dateRange = [new Date(year, month, 1), new Date()]
            } else if (val === 'thisYear') {
                state2.start = moment(new Date(year, 0, 1)).format('YYYY-MM-DD')
                state2.end = moment(new Date()).format('YYYY-MM-DD')
                state.dateRange = [new Date(year, 0, 1), new Date()]
            } else if (val === 'thisWeek') {
                const last7days = new Date(year, month, day - 7)
                state2.start = moment(last7days).format('YYYY-MM-DD')
                state2.end = moment(new Date()).format('YYYY-MM-DD')
                state.dateRange = [last7days, new Date()]
            } else if (val === 'lastMonth') {
                const lastMonth = new Date(year, month - 1, 1)
                const lastMonthEnd = new Date(year, month, 0)
                state2.start = moment(lastMonth).format('YYYY-MM-DD')
                state2.end = moment(lastMonthEnd).format('YYYY-MM-DD')
                state.dateRange = [lastMonth, lastMonthEnd]
            } else if (val === 'all') {
                state2.start = ''
                state2.end = ''
                state.dateRange = []
            }
        }

        const onHandleCustomRange = async () => {
            state2.start = state.dateRange[0]
            state2.end = state.dateRange[1]
            // const pagination = route.query.limit && route.query.page ? { limit: route.query.limit, page: 1 } : null
            state2.filterType = 'customrange'
            // route.query.filterType = 'customrange'
            route.query.start = new Date(state2.start)
            route.query.end = new Date(state2.end)
            route.query.mode = new Date(state2.mode)
            await getAllTransactions()
        }

        const onNavigateAllTransactions = query => {
            store.dispatch('Transaction/getAllTransactions', query)
        }

        const onNavigateAllSalesTransactions = query => {
            store.dispatch('Transaction/getAllSalesTransactions', query)
        }

        const onNavigateAllPurchaseTransactions = query => {
            store.dispatch('Transaction/getAllPurchaseTransactions', query)
        }

        const onNavigateAllExpenseTransactions = query => {
            store.dispatch('Transaction/getAllExpenseTransactions', query)
        }

        const onNavigateAllOtherTransactions = query => {
            store.dispatch('Transaction/getAllOtherTransactions', query)
        }

        const chartOptions = computed(() => {
            return {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            // fontColor: 'rgba(0, 0, 0, 0.6)',
                            fontFamily: 'Circular Std Book',
                            fontSize: 12,
                            fontStyle: 'normal',
                            fontWeight: 200,
                            fontStretch: 'normal',
                            lineHeight: 1
                        }
                    }
                },
                responsive: true,
                scales: {
                    x: {
                        grid: {
                            display: false
                            //   drawBorder: BORDER,
                            //   drawOnChartArea: CHART_AREA,
                            //   drawTicks: TICKS,
                        }
                    },
                    y: {
                        grid: {
                            display: true,
                            borderWidth: 1,
                            color: '#e9eaee'

                            //   drawBorder: false,
                            //   color: function(context) {
                            //     if (context.tick.value > 0) {
                            //       return Utils.CHART_COLORS.green;
                            //     } else if (context.tick.value < 0) {
                            //       return Utils.CHART_COLORS.red;
                            //     }

                            //     return '#000000';
                            //   },
                        },
                        beginAtZero: true
                    }
                }
            }
        })

        watch(
            () => expensesOverview.value,
            (prevValue, newValue) => {
                const { data } = prevValue
                state.expensesGraphData = {
                    labels: data.days,
                    datasets: [
                        {
                            label: 'Expenses',
                            backgroundColor: '#f8fafe',
                            borderColor: '#f8fafe',
                            borderWidth: 1,
                            fill: true,
                            pointStyle: 'circle',
                            pointRadius: 5,
                            pointHoverRadius: 5,
                            tension: 0.5,
                            data: data.data
                        }
                    ]
                }
            }
        )

        watch(
            () => salesOverview.value,
            (prevValue, newValue) => {
                const { data } = prevValue
                state.salesGraphData = {
                    labels: data.days,
                    datasets: [
                        {
                            label: 'Sales',
                            backgroundColor: '#f8fafe',
                            borderColor: '#f8fafe',
                            borderWidth: 1,
                            fill: true,
                            pointStyle: 'circle',
                            pointRadius: 5,
                            pointHoverRadius: 5,
                            tension: 0.5,
                            data: data.data
                        }
                    ]
                }
            }
        )

        watch(
            () => purchaseOverview.value,
            (prevValue, newValue) => {
                const { data } = prevValue
                state.purchasesGraphData = {
                    labels: data.days,
                    datasets: [
                        {
                            label: 'Purchases',
                            backgroundColor: '#f8fafe',
                            borderColor: '#f8fafe',
                            borderWidth: 1,
                            fill: true,
                            pointStyle: 'circle',
                            pointRadius: 5,
                            pointHoverRadius: 5,
                            tension: 0.5,
                            data: data.data
                        }
                    ]
                }
            }
        )

        const clickModalTxn = ref(false)
        const closeModal = () => {
            clickModalTxn.value = false
        }
        watch(
            () => state.salesGraphFilter,
            (value, prev) => {
                // console.log(value, prev)
                if (value === 'last7Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange()
                    store.dispatch('Transaction/getSalesGraphData', {
                        startDate: startDate,
                        endDate: endDate,
                        type: 'sales'
                    })
                } else if (value === 'last30Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange(30)
                    store.dispatch('Transaction/getSalesGraphData', {
                        startDate: startDate,
                        endDate: endDate,
                        type: 'sales'
                    })
                }
            }
        )

        watch(
            () => state.purchaseGraphFilter,
            (value, prev) => {
                // console.log(value, prev)
                if (value === 'last7Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange()
                    store.dispatch('Transaction/getPurchasesGraphData', {
                        startDate: startDate,
                        endDate: endDate,
                        type: 'purchases'
                    })
                } else if (value === 'last30Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange(30)
                    store.dispatch('Transaction/getPurchasesGraphData', {
                        startDate: startDate,
                        endDate: endDate,
                        type: 'purchases'
                    })
                }
            }
        )

        watch(
            () => state.expenseGraphFilter,
            (value, prev) => {
                // console.log(value, prev)
                if (value === 'last7Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange()
                    store.dispatch('Transaction/getExpensesGraphData', {
                        startDate: startDate,
                        endDate: endDate,
                        type: 'expenses'
                    })
                } else if (value === 'last30Days') {
                    const { startDate, endDate } = getLastSevenDaysDateRange(30)
                    store.dispatch('Transaction/getExpensesGraphData', {
                        startDate: startDate,
                        endDate: endDate,
                        type: 'expenses'
                    })
                }
            }
        )

        const expensesLineData = computed(() => state.expensesGraphData)
        const purchasesLineData = computed(() => state.purchasesGraphData)
        const salesLineData = computed(() => state.salesGraphData)

        const refreshHandler = () => {
            const query = {
                limit: 25,
                page: 1,
                start: new Date(year, month, 1),
                end: new Date()
            }
            store.dispatch('Transaction/getAllTransactions', query)
            store.dispatch('Transaction/getAllSalesTransactions', query)
            store.dispatch('Transaction/getAllPurchaseTransactions', query)
            store.dispatch('Transaction/getAllExpenseTransactions', query)
            store.dispatch('Transaction/getAllOtherTransactions', query)
            store.dispatch('Transaction/getTransactionSummary', query)
        }

        const showBalanceDetails = () => {
            store.dispatch('Transaction/showBalanceBox', true)
        }

        const toggleTransferModal = status => {
            store.dispatch('Transaction/toggleTransferModal', status)
        }

        const toggleTransactionImportModal = status => {
            store.dispatch('Transaction/toggleTransactionImportModal', status)
        }

        const exportTransactions = () => {
            if (plan.value?.businessPlan === 'Micro Business Plan') {
                Swal.fire({
                    title: `Upgrade Plan`,
                    text: `Exporting of data is only available for Paid plans`,
                    showCloseButton: true,
                    confirmButtonText: 'Upgrade',
                    confirmButtonColor: '#a94442'
                }).then(async result => {
                    if (result.isConfirmed) {
                        await router.replace({ name: 'Plan' })
                    }
                })
                return
            }
            // onChangeFixed(state2.period)
            state2.start = state.dateRange[0]
            state2.end = state.dateRange[1]
            const query = {
                page: route.query.page || 1,
                limit: route.query.limit || 25,
                filterType: state2.filterType || 'customrange',
                start: moment(state2.start).format('YYYY-MM-DD'),
                end: moment(state2.end).format('YYYY-MM-DD') + ' 23:59:59',
                howLongInNumber: state2.howLongInNumber || 30,
                oneOfDaysWeeksMonthsYears: state2.oneOfDaysWeeksMonthsYears || 'days',
                sort: 'date',
                filterByType: state.type,
                filterByChannel: state.channel,
                filterByStaff: state.staff,
                filterByPaymentMethod: state.paymentMethod,
                filterBySubtype: state.subtype
            }

            store.dispatch('Transaction/exportTransactions', query).then(response => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `${orgData.value?.org}-transactions-export.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                // state.downloading = false
            })
        }

        const handleSelectedTag = (tag) => {
            state.tags.push(tag);
            state.myTags = state.myTags.filter(myTag => myTag.text !== tag.text);
            const filterTags = state.tags.map(tag => tag.text).join(',');
            updateRoute('tags', filterTags)
        }

        const handleChangeTag = (tags) => {
            state.tags = tags;
            // state.myTags.push(tags);
            const filterTags = state.tags.map(tag => tag.text).join(',');
            updateRoute('tags', filterTags)
        }

        const search = () => {
            const tags = state.tags
                ? state.tags.map(tag => tag.text)
                : []
            const query = {
                page: route.query.page || 1,
                limit: route.query.limit || 25,
                filterType: 'search',
                start: new Date(2020, 0, 1),
                end: new Date(),
                start: state.dateRange[0] || new Date(2020, 0, 1),
                end: state.dateRange[1] || new Date(),
                howLongInNumber: state2.howLongInNumber || 30,
                oneOfDaysWeeksMonthsYears: state2.oneOfDaysWeeksMonthsYears || 'days',
                sort: 'date',
                q: state.searchVal,
                filterByType: state.type,
                filterByChannel: state.channel,
                filterByStaff: state.staff,
                filterByPaymentMethod: state.paymentMethod,
                filterBySubtype: state.subtype,
                filterByTags: tags,
            }

            store.dispatch('Transaction/getAllTransactions', query)
            store.dispatch('Transaction/getTransactionSummary', query)
        }
        onMounted(() => {
         const urlSearchParams = new URLSearchParams(window.location.search);
          const trxParam = urlSearchParams.get('name') || 'Transactions';

         trxValue.value = trxParam.replace('-', ' ');
          });

        onMounted(() => {
            if (role.value === 'salesTeller') {
                onChangeTable('isSales')
            }
            const { startDate, endDate } = getLastSevenDaysDateRange(30)
            if (route.query?.date) {
                const dateRange = route.query?.date.split(',')
                state.dateRange = [new Date(dateRange[0]), new Date(dateRange[1])]
                state2.start = formatDateFilter(new Date(dateRange[0]))
                state2.end = formatDateFilter(new Date(dateRange[1]))
            }

            const query = {
                limit: route.query?.limit || 25,
                page: route.query?.page || 1,
                sort: route.query?.sort || 'date',
                mode: route.query?.mode || 'desc',
                filterType: route.query?.filterType || state2.filterType || 'customrange',
                start: route.query?.start || new Date(year, month, 1),
                end: route.query?.end || new Date(),
                q: state.searchVal,
                filterByType: state.type,
                filterByChannel: state.channel,
                filterByStaff: state.staff,
                filterByPaymentMethod: state.paymentMethod,
                filterBySubtype: state.subtype
            }

            store.dispatch('Settings/getTeamMembers')
            store.dispatch('Settings/getBankAccounts')
            // store.dispatch('Transaction/getAllSalesTransactions', query)
            store.dispatch('Transaction/getAllTransactions', query)
            // store.dispatch('Transaction/getAllPurchaseTransactions', query)
            // store.dispatch('Transaction/getAllExpenseTransactions', query)
            // store.dispatch('Transaction/getAllOtherTransactions', query)
            store.dispatch('Transaction/getTransactionSummary', query)

            store.dispatch('Transaction/getSalesGraphData', {
                startDate: startDate,
                endDate: endDate,
                type: 'sales'
            })
            store.dispatch('Transaction/getPurchasesGraphData', {
                startDate: startDate,
                endDate: endDate,
                type: 'purchases'
            })
            store.dispatch('Transaction/getExpensesGraphData', {
                startDate: startDate,
                endDate: endDate,
                type: 'expenses'
            })
            store.dispatch('Tag/getTags').then(data => {
                if (data.status) {
                    state.myTags = data?.data || []
                }
            })

            store.dispatch('Settings/getPlan')
            const params = new URLSearchParams(window.location.search)
            let stat = params.get('stat')

            if (stat) {
                state2.period = stat
                onChangeFixed(stat)
            }

            // Listen for clicks outside the modal
            window.addEventListener('click', event => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (!(event.target.closest('.clickModalIcon') || event.target === modalIcon) && clickModalTxn.value === true) {
                    closeModal()
                }
            })
        })

        return {
            role,
            state,
            state2,
            transactions,
            onHandleCustomRange,
            onNavigateAllTransactions,
            onNavigateAllSalesTransactions,
            onNavigateAllPurchaseTransactions,
            onNavigateAllExpenseTransactions,
            onNavigateAllOtherTransactions,
            expenses,
            others,
            purchases,
            sales,
            summary,
            formatAmount,
            formatDate,
            onChangeTable,
            refreshHandler,
            onChangeFixed,
            todaysDate,
            showBalanceDetails,
            toggleTransferModal,
            toggleTransactionImportModal,
            exportTransactions,
            chartOptions,
            expensesLineData: expensesLineData ?? {},
            purchasesLineData: purchasesLineData ?? {},
            salesLineData: salesLineData ?? {},
            rolePermissions,
            getAllTransactions,
            currency,
            search,
            transactionErrors,
            clickModalTxn,
            closeModal,
            staffList,
            bankAccounts,
            inflows,
            expenseTypes,
            staffFilter,
            setStaffFilter,
            items,
            header,
            footer,
            handleSelectedTag,
            handleChangeTag,
            updateRoute,
            trxValue,
        }
    }
}
</script>

<style>
:root {
  --v-calendar-datepicker-icon-color:#132c8c;
  --v-calendar-action-color:#132c8c;
  --v-calendar-day-hover-bg-color:#febf12;
  --v-calendar-active-bg-color:#132c8c;
  --v-calendar-range-bg-color:#fffceb;
  /* --v-calendar-range-text-color:#febf12; */
}

.search-input-wrapper input[data-search-input=true] {
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #e3e8ee;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.search-input-wrapper .search-icon.shortcut {
    width: 0;
    height: 0;
    cursor: text;
    right: 0;
    bottom: 0;
    background-color: #e7eff7;
    border-radius: 3px;
    z-index: 50;
}

.search-input-wrapper .search-icon.shortcut::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 2px;
    transform: rotate(25deg);
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    z-index: 51;
    background-color: #96b9dc;
}

.v-calendar {
    width: 100% !important;
    min-width: 100% !important;
}

.v-calendar .input-field.long {
    min-width: 100% !important;
}

.v-calendar .input-field input {
    width: 100% !important;
    height: 35px;
    padding-left: 30px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #e3e8ee;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 647px) {
    .v-calendar .content {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 960px) {
    .filter-wrapper .ph2 {
        padding-left: 0;
        padding-right: 0;
    }
    .filter-wrapper .pl1 {
        padding-left: 0.25rem;
    }
    .filter-wrapper .pr1 {
        padding-right: 0.25rem;
    }
    .filter-wrapper .pl2 {
        padding-left: 0.5rem;
    }
    .filter-wrapper .pr2 {
        padding-right: 0.5rem;
    }
    .filter-wrapper .pb3 {
        padding-bottom: 0;
    }
    .filter-wrapper .search-btn {
        margin-top: 1.5rem;
    }
}

.filter-input {
    padding: 8px 5px;
    /* font-size: 15px;
    font-weight: 400; */
}

.v3ti {
    width: 100%;
    border: 1px solid #e3e8ee;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.v3ti .v3ti-content--select {
    padding-right: unset;
}

.v3ti .v3ti-new-tag {
    padding: 8px 5px;
}

.hlyi45ju8 button {
    padding: 0 5px;
}
</style>
